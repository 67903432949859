// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import {
    Icon, Link, Tooltip, useModal
} from '@jutro/components';
import _ from 'lodash';
import { useAuthentication } from 'gw-digital-auth-react';
import { withIntl, useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { PolicyService } from 'gw-capability-policy';
import metadata from './FileUploadViewerComponent.metadata.json5';
import styles from './FileUploadViewerComponent.module.scss';
import messages from '../PolicyCommonDocuments.messages';

function FileUploadViewerComponent(props) {
    const {
        data: policyDocuments,
        intl,
        getDownloadLink,
        onDocumentDelete,
        onDocumentUpload
    } = props;
    const { documents } = policyDocuments;
    const { maxFileUploadSize } = appConfig;
    const auth = useAuthentication();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const {
        showAlert
    } = useModal();
    const handleDocumentDelete = useCallback(
        (event, item) => {
            event.preventDefault();
            onDocumentDelete(item);
        },
        [onDocumentDelete]
    );

    const handleDocumentUpload = useCallback(
        (item) => {
            onDocumentUpload(item);
        },
        [onDocumentUpload]
    );

    const getFileName = useCallback(
        (document) => {
           /*  let disabledLink = true; 
            if(document.author === auth.user_name){
                disabledLink = false;
            } else if(document.author === 'Auto-generated'){
                disabledLink = false;
            }
            if (!disabledLink) {
                return (
                    <Link
                        icon="mi-insert_drive_file"
                        className={styles.fileTypeIcon}
                        onClick={(event) => downloadFile(document, event)}
                        target="_blank"
                    >
                        {document.name}
                    </Link>
                );
            } */
            return (<>
                <Icon icon="mi-insert_drive_file" className={styles.fileTypeIcon} />
                {document.name}
            </>);
        },
        [getDownloadLink]
    );

    const downloadFile = useCallback(async (item, event) => {
        const isUserClick = event && event.type == 'click' && event.clientX && event.clientY;
        if (!isUserClick) {
            event?.preventDefault();
            return;
        }
        const documentContent =
        await PolicyService.getDocumentDowloadLink(item.publicID, authHeader);
        if (documentContent?.error) {
            showAlert({
                title: 'Document Error',
                message: translator(documentContent.error),
                status: 'error',
                confirmButtonText: messages.okText
            }).catch(_.noop);
        } else {
            let fileType = '';
            if (documentContent.extension === 'tif') {
                fileType = 'image/tiff';
            } else if (documentContent.extension === 'pdf') {
                fileType = 'application/pdf';
            }
            const byChar = atob(documentContent.byteArray);
            const bytNum = new Array(byChar.length);
            for (let i = 0; i < byChar.length; i++) {
                bytNum[i] = byChar.charCodeAt(i);
            }
            const byArray = new Uint8Array(bytNum);
            const blob = new Blob([byArray], { type: fileType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = item.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }, []);

    const getFormattedDate = useCallback(
        (document) => {
            return intl.formatDate(new Date(document.dateModified), { year: 'numeric', month: 'short', day: 'numeric' });
        },
        [intl]
    );

    const getDeleteIcon = useCallback(
        (document) => {
            if (auth.user_name === document.author) {
                return (
                    <Link href={document.name} onClick={(e) => handleDocumentDelete(e, document)}>
                        <Icon icon="mi-delete" />
                    </Link>
                );
            }
            return <Icon icon="mi-delete" />;
        },
        [handleDocumentDelete]
    );

    const overrides = {
        fileUploadComponent: {
            visible: !policyDocuments.isReadOnly,
            maxFileSizeKB: maxFileUploadSize !== undefined
                ? maxFileUploadSize
                : 20000
        },
        uploadedFileTableDetail: {
            placeholder: policyDocuments.isReadOnly && !documents.length ? translator(messages.noDocumentFound) : '',
            data: documents,
        }
    };

    function MetadataTooltip({ children, ...rest }) {
        const child = Array.isArray(children) ? children[0] : children;
        return (
            <Tooltip {...rest} content={translator(messages.documentUploadTooltip)}>
                {child}
            </Tooltip>
        );
    }
    MetadataTooltip.propTypes = {
        title: PropTypes.shape({})
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onFileUpload: handleDocumentUpload,
            getFileName: getFileName,
            getFormattedDate: getFormattedDate,
            getDeleteIcon: getDeleteIcon
        },
        resolveComponentMap: {
            tooltipcomponent: MetadataTooltip
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, documents, id, path, overrides);
        },
        [documents, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={documents}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

FileUploadViewerComponent.propTypes = {
    data: PropTypes.shape({
        canDelete: PropTypes.bool
    }),
    intl: PropTypes.func.isRequired,
    getDownloadLink: PropTypes.func.isRequired,
    onDocumentDelete: PropTypes.func.isRequired,
    onDocumentUpload: PropTypes.func.isRequired
};

FileUploadViewerComponent.defaultProps = {
    data: {
        canDelete: false
    }
};

export default withIntl(FileUploadViewerComponent);
